import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import PrimaryTags from "../components/Index/primaryTags"
import Layout from "../components/Layout/layout"
import Seo from "../components/seo"
import FeedSection from "../components/Index/feedSection"
import PeopleBlock from "../components/Index/peopleBlock";
import ReduxWrapper from "../redux/reduxWrapper"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allFeedStories {
        nodes {
          id
          description
          exerpt
          url
          primaryTag
          tags
          title
          createdAt
          updatedAt
          lastModifiedDate
          media {
            url
            type
            old_url
            feedMediaType
            title
            length
            nodeName
            old_url
            type
          } 
          feedSource {
            id
            type
            sourceUrl
            favicon
            primaryTag
            createdAt
            updatedAt
            user {
              email
              username
              roles
            }
          }
        }
      }
      allPrimaryTags {
        nodes {
          label
        }
      }
    }
  `)

  const [updateLoginModal, setUpdateLoginModal] = useState(true)

  return (
    <Layout
      updateLoginModal={updateLoginModal}
      setUpdateLoginModal={setUpdateLoginModal}
    >
      <Seo title="Home" />
      <PrimaryTags primaryTags={data.allPrimaryTags.nodes} />
      <PeopleBlock />
      <FeedSection feedStories={data.allFeedStories.nodes} />
    </Layout>
  )
}

const WrappedPage = (props) => (
    <ReduxWrapper element={<IndexPage {...props} />} />
)
export default WrappedPage

